<template>
  <div class="w-100 d-flex flex-column h-100">
    <base-tool-bar
      title="Informations"
      subtitle="Informations générales et état civil du collaborateur"
    ></base-tool-bar>
    <base-form class="mr-md-3" @click="updateCollaborateur" :loading="loading" shadow>
      <base-form-group title="Etat civil" dense icon="user">
        <base-form-row row>
          <base-input
            v-model="collaborateur_matricule"
            inputText="Matricule"
            :errors="feedback.collaborateur_matricule"
          ></base-input>
        </base-form-row>
        <base-form-row row>
          <base-input v-model="collaborateur_nom" inputText="Nom" :errors="feedback.collaborateur_nom"></base-input>
          <base-input
            v-model="collaborateur_prenom"
            inputText="Prénom"
            :errors="feedback.collaborateur_prenom"
          ></base-input>
        </base-form-row>
        <base-form-row row>
          <base-inputDatePicker
            v-model="collaborateur_date_naissance"
            inputText="Date de naissance"
            :errors="feedback.collaborateur_date_naissance"
          ></base-inputDatePicker>
          <base-input
            v-model="collaborateur_rinpp"
            inputText="N° sécurité sociale (RINPP)"
            :errors="feedback.collaborateur_rinpp"
          ></base-input>
        </base-form-row>
        <base-form-row row>
          <base-select
            inputText="Sexe"
            v-model.number="collaborateur_sexe_id"
            :options="data.sexes"
            :errors="feedback.collaborateur_sexe_id"
          ></base-select>
          <base-select
            inputText="Nationalité"
            v-model.number="collaborateur_nationalite_id"
            :options="data.nationalites"
            :errors="feedback.collaborateur_nationalite_id"
          ></base-select>
        </base-form-row>
        <base-form-row>
          <base-input
            v-model="collaborateur_adresse_naissance"
            inputText="Ville de naissance"
            :errors="feedback.collaborateur_adresse_naissance"
          ></base-input>
        </base-form-row>
        <base-form-row row>
          <base-select
            inputText="Situation de famille"
            v-model.number="collaborateur_situation_famille_id"
            :options="data.situationsFamille"
            :errors="feedback.collaborateur_situation_famille_id"
          ></base-select>
          <base-input-number
            v-model="collaborateur_enfants"
            inputText="Nombre d'enfants à charge"
            :errors="feedback.collaborateur_enfants"
          ></base-input-number>
        </base-form-row>
        <base-form-row>
          <base-select
            inputText="Formation initiale"
            v-model.number="collaborateur_niveau_formation_id"
            :options="data.niveauxFormation"
            :errors="feedback.collaborateur_niveau_formation_id"
          ></base-select>
        </base-form-row>
      </base-form-group>
    </base-form>
  </div>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import { mapActions, mapGetters } from "vuex";
import { errHandler } from "@/helpers.js";
import BaseInput from "@/components/bases/Input.vue";
import BaseInputNumber from "@/components/bases/InputNumber.vue";
import BaseSelect from "@/components/bases/Select.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormGroup from "@/components/bases/FormGroup.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseToolBar from "@/components/bases/ToolBar.vue";

export default {
  components: {
    BaseInput,
    BaseInputNumber,
    BaseSelect,
    BaseInputDatePicker,
    BaseForm,
    BaseFormGroup,
    BaseFormRow,
    BaseToolBar,
  },
  data() {
    return {
      feedback: {},
      loading: false,
      statut: null,
    };
  },
  methods: {
    ...mapActions({
      _updateCollaborateur: "collaborateurs/updateCollaborateur",
    }),
    updateCollaborateur: function () {
      this.feedback = {};
      this.loading = true;
      this._updateCollaborateur()
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
  },
  computed: {
    ...mapFields("collaborateurs", [
      "collaborateur.collaborateur_matricule",
      "collaborateur.collaborateur_nom",
      "collaborateur.collaborateur_prenom",
      "collaborateur.collaborateur_date_naissance",
      "collaborateur.collaborateur_rinpp",
      "collaborateur.collaborateur_sexe_id",
      "collaborateur.collaborateur_nationalite_id",
      "collaborateur.collaborateur_adresse_naissance",
      "collaborateur.collaborateur_situation_famille_id",
      "collaborateur.collaborateur_enfants",
      "collaborateur.collaborateur_niveau_formation_id",
      "collaborateur.collaborateur_hierarchique_id",
    ]),
    ...mapGetters({
      data: "collaborateurs/data",
    }),
  },
};
</script>
<style lang="css"></style>
